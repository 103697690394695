<template>
	<main class="sa-view-stories-archives">
		<div class="padding-x-24 padding-y-48">
			<div class="row max-width-grid display-block">
				<div class="section-header margin-b-24 bp-768:margin-b-48">
					<h2 class="section-header__title margin-0">
						News Archives
					</h2>
				</div>
				<div
					class="c-archives__filters-container row margin-b-12 bp-1024:margin-b-24"
				>
					<div
						class="col-1-of-1 margin-b-12 bp-1024:padding-r-24 bp-1024:margin-b-0 bp-1024:col-1-of-4"
					>
						<autocomplete
							:items="$root.divisions"
							placeholder="Select Division..."
							@item-selected="set_selected_division"
						/>
					</div>
					<div
						class="col-1-of-1 margin-b-12 bp-1024:padding-r-24 bp-1024:margin-b-0 bp-1024:col-1-of-4"
					>
						<autocomplete
							:items="$root.tags"
							placeholder="Select Tag..."
							@item-selected="set_selected_tag"
						/>
					</div>
					<div
						class="c-archives__search border display-flex col-1-of-1 bp-1024:col-2-of-4"
					>
						<input
							placeholder="Search by keyword..."
							type="text"
							class="c-archives__search-input font-size-14 font-weight-bold padding-x-24 text-brand reset"
							v-model="search_query"
							@keyup.enter="get_stories"
						/>
						<button
							class="c-archives__search-icon border-l cursor-pointer padding-x-24 reset text-brand"
							aria-label="Search NCAA.org"
							@click.prevent="get_stories"
						>
							<search-icon
								class="height-100"
								size="20"
							></search-icon>
						</button>
					</div>
				</div>
				<loading-indicator
					vertical-space="500"
					v-if="loading_stories"
				/>
				<transition name="fade">
					<div v-if="!loading_stories">
						<div class="overflow-x-scroll">
							<table
								class="c-archives__table width-100 text-align-left"
								style="border-collapse:collapse"
							>
								<caption v-if="table_label">
									{{
										table_label
									}}
								</caption>
								<thead>
									<tr>
										<th
											class="padding-8 white-space-nowrap"
										>
											Title
										</th>
										<th
											class="padding-8 white-space-nowrap"
										>
											Category
										</th>
										<th
											class="padding-8 white-space-nowrap"
										>
											Posted on
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										class="border-t"
										v-for="story in pageOfItems"
										:key="story.story_id"
									>
										<td
											class="padding-8 white-space-nowrap"
										>
											<navigation-link
												_class="text-decoration-none hover:text-decoration-underline"
												:url="story.story_path"
											>
												{{ story.story_headline }}
											</navigation-link>
										</td>
										<td
											class="padding-8 white-space-nowrap"
										>
											{{ story.sport_title }}
										</td>
										<td
											class="padding-8 white-space-nowrap"
										>
											{{ story.story_postdate }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<jw-pagination
							class="border-t border-style-dashed margin-t-12 padding-t-12 width-100"
							:page-size="25"
							:items="stories"
							@changePage="onChangePage"
						></jw-pagination>
					</div>
				</transition>
			</div>
		</div>
	</main>
</template>

<script>
import { SearchIcon } from 'vue-feather-icons';
import { get } from '@/helpers';
import Autocomplete from '@/components/Common/Autocomplete';
import LoadingIndicator from '@/components/Common/LoadingIndicator';
import NavigationLink from '@/components/Common/NavigationLink';

export default {
	name: 'StoryArchives',

	components: {
		Autocomplete,
		NavigationLink,
		SearchIcon,
		LoadingIndicator,
	},

	data: () => ({
		loading_stories: false,
		pagination_index: 1,
		pageOfItems: [],
		search_query: '',
		selected_division: 0,
		selected_tag: 0,
		stories: [],
		table_label: '',
	}),

	methods: {
		get_stories() {
			this.loading_stories = true;

			let request_options = {
				index: this.pagination_index,
				page_size: 0,
				division: this.selected_division,
				tag: this.selected_tag,
				mode: "waterfall",
				sport: 2,
				season: 0,
				search: this.search_query,
			};

			get(
				`${this.$root.proxy}/services/archives.ashx/stories`,
				request_options,
			)
				.then(response => {
					this.stories = response;
					if (this.search_query) {
						this.table_label = `Results for keyword: "${this.search_query}"`;
					} else {
						this.table_label = '';
					}
					this.loading_stories = false;
				})
				.catch(error => {
					console.log(error);
					this.loading_stories = false;
				});
		},
		onChangePage(pageOfItems) {
			this.pageOfItems = pageOfItems;
		},
		set_selected_division(division) {
			if (division) {
				this.selected_division = division.id;
			} else {
				this.selected_division = 0;
			}
		},
		set_selected_tag(tag) {
			if (tag) {
				this.selected_tag = tag.id;
			} else {
				this.selected_tag = 0;
			}
		},
	},

	watch: {
		selected_division(newVal, oldVal) {
			if (newVal != oldVal) {
				this.get_stories();
			}
		},
		selected_tag(newVal, oldVal) {
			if (newVal != oldVal) {
				this.get_stories();
			}
		},
	},

	created() {
		if (this.$route.query.q) {
			this.search_query = this.$route.query.q;
		}
		this.get_stories();
	},
};
</script>

<style lang="scss" scoped>
.c-archives {
	//for naming
	&__select {
		height: 65px;
		&:hover,
		&:focus {
			cursor: pointer;
		}
		&-icon {
			pointer-events: none;
		}
	}
	&__search {
		height: 65px;
		&-input {
			flex: 1 1 auto;
		}
		&-icon {
			flex: 0 0 auto;
		}
	}
}
</style>
