<template>
	<div
		class="component c-autocomplete position-relative z-index-10"
		v-click-outside="close_dropdown"
	>
		<div
			class="display-flex"
			tabindex="0"
			@blur="close_dropdown"
			@focus="open_dropdown"
		>
			<input
				class="c-autocomplete__input border text-transform-capitalize text-brand font-size-14 font-weight-bold padding-x-24 reset width-100"
				type="text"
				v-model="search"
				@input="open_dropdown"
				@focus="open_dropdown"
				:placeholder="placeholder ? placeholder : undefined"
				@keydown.enter="on_enter()"
				@keydown.up="on_arrow('up')"
				@keydown.down="on_arrow('down')"
				@keydown.esc="close_dropdown"
			/>
			<button
				class="c-autocomplete__icon border border-l-none utility-flex-center reset transition"
				@click="search != '' ? clear() : close_dropdown()"
				@keydown.enter="search != '' ? clear() : close_dropdown()"
				@blur="close_dropdown"
				:class="{ 'cursor-pointer': search != '' }"
			>
				<filter-icon
					class="text-brand"
					size="20"
					v-if="search === ''"
				/>
				<x-icon class="text-brand transition" size="20" v-else />
			</button>
		</div>
		<ul
			class="c-autocomplete__results bg-color-white border border-t-none overflow-y-auto padding-y-6 position-absolute left-0 reset-list width-100"
			v-show="is_toggled"
		>
			<li
				class="c-autocomplete_result cursor-pointer padding-y-6 padding-x-12 hover:bg-color-primary hover:text-on-primary transition"
				v-for="(result, index) in results"
				:key="index"
				@click="set_result(index)"
				:class="{
					'bg-color-primary text-on-primary': index === arrow_counter,
				}"
			>
				{{ result.name }}
			</li>
			<li v-if="!results.length" class="padding-y-6 padding-x-12">
				No matching results
			</li>
		</ul>
	</div>
</template>

<script>
import { FilterIcon, XIcon } from 'vue-feather-icons';

export default {
	name: 'Autocomplete',

	components: {
		FilterIcon,
		XIcon,
	},

	props: {
		items: {
			type: Array,
			required: false,
			default: () => [],
		},
		placeholder: {
			type: String,
			required: false,
			default: null,
		},
	},

	data: () => ({
		arrow_counter: -1,
		is_toggled: false,
		search: '',
	}),

	computed: {
		results() {
			if (this.search === '') {
				return this.items;
			} else {
				return this.items.filter(
					item =>
						item.name
							.toLowerCase()
							.indexOf(this.search.toLowerCase()) > -1,
				);
			}
		},
	},

	methods: {
		open_dropdown() {
			this.is_toggled = true;
		},
		close_dropdown() {
			this.is_toggled = false;
		},
		toggle_dropdown() {
			this.is_toggled = !this.is_toggled;
		},
		on_arrow(direction) {
			if (
				this.arrow_counter < this.results.length - 1 &&
				direction === 'down'
			) {
				this.arrow_counter = this.arrow_counter + 1;
			}
			if (this.arrow_counter > 0 && direction === 'up') {
				this.arrow_counter = this.arrow_counter - 1;
			}
		},
		on_enter() {
			let result = this.results[this.arrow_counter];
			this.search = result.name;
			this.emit_selected_item(result);
			this.toggle_dropdown();
			this.arrow_counter = -1;
		},
		set_result(index) {
			let result = this.results[index];
			this.search = result.name;
			this.emit_selected_item(result);
			this.close_dropdown();
		},
		emit_selected_item(value) {
			console.log(value);
			this.$emit('item-selected', value);
		},
		clear() {
			this.search = '';
			this.arrow_counter = -1;
			this.close_dropdown();
			this.$emit('item-selected');
		},
	},
};
</script>

<style lang="scss" scoped>
.c-autocomplete {
	&__input {
		flex: 0 1 auto;
		height: 65px;
		&-icon {
			pointer-events: none;
		}
	}
	&__icon {
		flex: 0 0 65px;
	}
	&__results {
		max-height: 280px;
		top: 65px;
	}
}
</style>
